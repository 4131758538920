require('./site/bootstrap')
// require('@fancyapps/fancybox')

// require('jquery-mask-plugin')

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';

$(function () {
	const rootEl = $('html')
	const scrollEl = $('html,body')
	const appEl = $('#app')
	const mobileNav = $('.header--nav').eq(1)

	/*/
	 *  Header visibility
	/*/
		let headerScrollLast = 0
		let headerScrollDesktop = $(window).width() > 1024

		const headerHide = current => {
			if (current == true) {
				rootEl.addClass('is--nav_hidden')
			} else {
				rootEl.removeClass('is--nav_hidden')
			}
		}

		if (headerScrollDesktop) {
			$(window).scroll(function (e) {
				let headerScrollCurrent = $(this).scrollTop()

				if (headerScrollCurrent > 140) {
					if (headerScrollCurrent > headerScrollLast) {
						headerHide(true)

					} else {
						headerHide(false)
					}
				} else {
					headerHide(false)
				}

				headerScrollLast = headerScrollCurrent;
			})
		}
	
	
	/*/
	 *  Header dropdown event
	/*/
		const headerDropdown = $('header .dropdown')

		headerDropdown.on('show.bs.dropdown', function () {
			rootEl.addClass('is--header_dropdown')
		})

		headerDropdown.on('hide.bs.dropdown', function () {
			rootEl.removeClass('is--header_dropdown')
		})

	
	/*/
	 *  Header navigation
	/*/
		$('[bb-nav]').click(() => {
			if (!rootEl.hasClass('is--nav')) {
				mobileNav.removeClass('d-none').addClass('d-flex')
	
				setTimeout(() => {
					rootEl.addClass('is--nav')
				}, 100);
			} else {
				rootEl.removeClass('is--nav')

				setTimeout(() => {
					mobileNav.removeClass('d-flex').addClass('d-none')
				}, 500);
			}
		})

		$(document).keyup(function(e) {
			if (rootEl.hasClass('is--nav') && e.keyCode == 27) {
				rootEl.removeClass('is--nav')

				setTimeout(() => {
					mobileNav.removeClass('d-flex').addClass('d-none')
				}, 500);
			}
		})

		$('.dropdown').on('show.bs.dropdown', function () {
			rootEl.removeClass('is--nav')

			setTimeout(() => {
				mobileNav.removeClass('d-flex').addClass('d-none')
			}, 500);
		})


	/*/
	 *  Header search
	/*/
		const headerSearch = $('.header--search')
		const headerSearchResults = $('.header--results')
		const headerSearchTrigger = headerSearch.find('.header--actions')
		let headerSearchQuery = ''

		var products = [];		
		var searching = false;
		headerSearch.find('input').on('input', async (e) => {
			var term = headerSearch.find('input').val();
			const searchUrl = headerSearch.find('input').data('search');
			if(!searching && products.length == 0) {
				headerSearchResults.html('');
				searching = true;
				const response = await axios.get(`${searchUrl}`);
				products = response.data;
			}

			const productsFiltered = products.filter(product => 
				product.title.toLowerCase().indexOf(term.toLowerCase()) >= 0 || product.code.toLowerCase().indexOf(term.toLowerCase()) >= 0 ,
			);

			if(productsFiltered.length == 0) {
				const empty = `<div class="header--results_empty">${window.Lang.searchNoResults}</div>`
				headerSearchResults.html(empty)
			} else {

				if(term.length == 0) {
					headerSearchResults.html('');
					return;
				}

				const htmlResults = productsFiltered.map(product => {
					if(!product.image) {
						return `<a href="${product.url}" class="search--i"><div class="mr mr--1by1"><svg class="icon"><use xlink:href="#ic-box"></use></svg></div><span>${product.title}</span></a>`
					} else {
						return `<a href="${product.url}" class="search--i"><div class="mr mr--1by1"><img src="${product.thumbnail}" class="mr--full" alt="" loading=lazy ></div><span>${product.title}</span></a>`
					}
				});

				headerSearchResults.html(htmlResults);
			}
		});

		headerSearch.find('.dropdown-menu').on('click', function (e) {
			e.stopPropagation()
		})

		headerSearch.on('show.bs.dropdown', function () {
			let input = $(this).find('input')

			setTimeout(function() {
				input.focus()
			}, 500);
		})

		headerSearch.on('hidden.bs.dropdown', function () {
			let input = $(this).find('input')
			
			input.val('')
			headerSearchResults.html('')
		})

		if (headerScrollDesktop) {
			headerSearch.hover(function(){
				headerSearchTrigger.dropdown('show')
			}, function() {
				// let focus = headerSearch.find('input')[0] === document.activeElement

				// if(!headerSearchQuery && !focus) {
				if(!headerSearchQuery) {
					headerSearchTrigger.dropdown('hide')
				}
			})
		}

	
	/*/
	 *  Header lang
	/*/
		const headerLang = $('.header--lang')
		const headerLangTrigger = headerLang.find('.header--actions')

		if (headerScrollDesktop) {
			headerLang.hover(function () {
				headerLangTrigger.dropdown('show')
			}, function () {
				headerLangTrigger.dropdown('hide')
			})
		}


	/*/
	 *  Anchor
	/*/
		$('[bb-anchor]').click(e => {
			e.preventDefault()

			const target = $(e.currentTarget).data('target')

			if (target) {
				scrollEl.animate({
					scrollTop: $(target).offset().top
				}, 1000);
			} else {
				scrollEl.animate({
					scrollTop: appEl.offset().top
				}, 1000);
			}
		})


	/*/
	 *  Fancybox
	/*/
		// $().fancybox({
		// 	selector : '[sd-fancybox]',
		// 	loop: true,
		// 	buttons: ['zoom', 'fullScreen', 'close'],
		// 	protect: true,
		// 	infobar: false,
		// 	arrows: true,
		// 	animationEffect: 'fade'
		// })


	/*/
	 *  Scroll reveal
	/*/
		const ScrollReveal = require('scrollreveal')({
			distance: '100px',
			scale: 1,
			duration: 500,
			reset: false,
			mobile: true,
			viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
		})
		
		ScrollReveal.reveal('.sr', { origin: 'bottom' })

		if (document.querySelector('.sr--right')) ScrollReveal.reveal('.sr--right', { origin: 'right' })
		if (document.querySelector('.sr--left')) ScrollReveal.reveal('.sr--left', { origin: 'left' })
		if (document.querySelector('.sr--top')) ScrollReveal.reveal('.sr--top', { origin: 'top' })
		if (document.querySelector('.sr--bottom')) ScrollReveal.reveal('.sr--bottom', { origin: 'bottom' })

	
	/*/
	 *  contact: position sticky
	/*/
		const contactAside = document.querySelector('.sc--form_aside')
		const contactWindowWidth = window.innerWidth
		const contactWindowHeight = window.innerHeight

		if (contactAside && contactWindowWidth >= 1200) {
			const contactAsideHeight = contactAside.offsetHeight
			const contactAsideOffet = 200

			if ((contactAsideHeight + contactAsideOffet) < contactWindowHeight) {
				contactAside.classList.add('is--sticky')
			}
		}
	

	/*/
	 *  contact: select placeholder
	/*/
		document.querySelectorAll('.form-select .form-control').forEach(e => {
			e.onchange = _ => e.value ? e.classList.remove('form-control-empty') : e.classList.add('form-control-empty')
		})


	/*/
	 *  contact: mask
	/*/
		// $(document).ready(function(){
		// 	let behavior = val => val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009'
		// 	let options = {
		// 		onKeyPress: function (val, e, field, options) {
		// 			field.mask(behavior.apply({}, arguments), options);
		// 		}
		// 	}
		// 	$('#tel').mask(behavior, options);
		// })


	/*/
	 *  collapse
	/*/
		$('[bb-collapse]').on('click', function(e) {
			const item = $(this).parent().parent()

			$(document).find('.collapse--i').find('.collapse').collapse('hide')

			setTimeout(function() {
				item.find('.collapse').collapse('toggle')
			}, 300);
		})

		$('.collapse').on('show.bs.collapse', function() {
			$(this).parent().addClass('show')

			// $('html,body').animate({
			// 	scrollTop: $(this).parent().offset().top - 100
			// }, 300);
		})

		$('.collapse').on('hide.bs.collapse', function () {
			$(this).parent().removeClass('show')
		})

		$('#product-collapse').on('show.bs.collapse', function(e) {
			const triggerEl = $(this).prev()
			triggerEl.addClass('is--collapsed')

			setTimeout(function() {
				triggerEl.remove()
			}, 500);
		})


	/*/
	 *  product: sticky
	/*/
		const productSticky = () => {
			const el = document.querySelector('.sc--product_image')
			const elImage = el.querySelector('.sc--product_image_inset')
			const offset = elImage.offsetHeight
			const windowH = window.innerHeight

			el.style.setProperty('--product-height', `${offset}px`)

			if (windowH >= (offset + 170)) {
				el.classList.add('is--sticky')
			} else {
				el.classList.remove('is--sticky')
			}
		}

		$(document).ready(function() {
			if (appEl.hasClass('product')) {
				productSticky()
			}
		})
		
		$(window).on('resize', function () {
			if (appEl.hasClass('product')) {
				setTimeout(function() {
					productSticky()
				}, 100)
			}
		})
	
	/*/
	* LGPD
	/*/
		const siteAgreeLGPD = document.querySelector('[bb-lgpd-toggle]')

		const siteVerifyLGPD = _ => {
			const element = document.querySelector('[bb-lgpd]')
			const verify = window.localStorage.getItem('lgpd_message_accepted')
			if (!verify) element.classList.add('is--visible')
		}

		window.addEventListener('load', _ => siteVerifyLGPD())
		siteAgreeLGPD.addEventListener('click', _ => {
			const element = document.querySelector('[bb-lgpd]')

			window.localStorage.setItem('lgpd_message_accepted', '1')
			element.classList.remove('is--visible')
		})
})


/*/
 *  Alert
/*/
	window.bbAlert = function (params) {
		params = {
			text: params.text ? params.text : '',
			class: params.class ? params.class : 'alert--success',
			seconds: params.seconds ? params.seconds : 6000,
		}

		const alert = document.querySelector('[bb-alert]')

		alert.classList.remove('alert--success')
		alert.classList.remove('alert--error')
		alert.classList.add(params.class)
		alert.innerHTML = params.text

		setTimeout(function () { alert.classList.add('is--visible') }, 500)
		setTimeout(function () { alert.classList.remove('is--visible') }, params.seconds)

		alert.addEventListener('click', function () {
			this.classList.remove('is--visible')
		})
	}

	window.bbAlertAlt = function (params) {
		params = {
			text: params.text ? params.text : '',
			class: params.class ? params.class : 'alert--success',
			seconds: params.seconds ? params.seconds : 6000,
		}

		const alert = document.querySelector('[bb-alert-alt]')

		alert.classList.remove('alert--success')
		alert.classList.remove('alert--error')
		alert.classList.add(params.class)
		alert.innerHTML = params.text

		setTimeout(function () { alert.classList.add('is--visible') }, 500)
		setTimeout(function () { alert.classList.remove('is--visible') }, params.seconds)

		alert.addEventListener('click', function () {
			this.classList.remove('is--visible')
		})
	}


/*/
 *  Form
/*/
	$(function () {
		'use strict'

		const forms = document.querySelectorAll('.needs-validation')
		Array.from(forms).forEach(function (form) {
			form.addEventListener('submit', function (event) {
				const formReturnSuccess = form.dataset.success
				const formReturnError = form.dataset.error
				const formName = form.dataset.name
				const button = form.querySelector('.btn[type="submit"]')
				const buttonLabel = form.querySelector('.btn[type="submit"] span')

				console.log(button)
				console.log(buttonLabel)

				if (!form.checkValidity()) {
					event.preventDefault()
					event.stopPropagation()

					this.querySelector(':invalid').focus()
					document.documentElement.classList.add('is--nav_hidden')
				} else {
					event.preventDefault()

					const data = new FormData(this)
					const options = {
						headers: { 'Content-Type': 'multipart/form-data' }
					}

					if (button) {
						button.disabled = true
						button.classList.add('is--loading')
						// buttonLabel.innerHTML = button.dataset.sending
					}

					window.axios.post(this.action, data, options)
						.then(_ => {
							form.reset()
							form.classList.remove('was-validated')

							if (formName == 'product') {
								bbAlertAlt({
									text: '<svg class="icon"><use xlink:href="#ic-check"></use></svg>' + formReturnSuccess,
									class: 'alert--success',
									seconds: 10000
								})
							} else {
								bbAlert({
									text: formReturnSuccess,
									class: 'alert--success',
									seconds: 10000
								})
							}
						}).catch(err => {
							console.error(err)

							if (formName == 'product') {
								bbAlertAlt({
									text: '<svg class="icon"><use xlink:href="#ic-warning"></use></svg>' + formReturnError,
									class: 'alert--error'
								})
							} else {
								bbAlert({
									text: formReturnError,
									class: 'alert--error'
								})
							}							
						}).finally(_ => {
							if (button) {
								setTimeout(() => {
									button.disabled = false
									button.classList.remove('is--loading')
									// buttonLabel.innerHTML = button.dataset.original
								}, 2000);
							}
						})
				}

				form.classList.add('was-validated')
			}, false)
		})
	})